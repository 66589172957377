import React from 'react';
import Layout from '../components/layout';
import '../components/layout.scss';
import SEO from '../components/seo';
import * as styles from './mentions-legales.module.scss';

const MentionsLegalesPage = () => (
  <Layout>
    <SEO title="Mentions légales" />
    <section className={styles.mentionsLegales}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Mentions légales</h1>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-sm-12">
            <div className="card">
              <h2>Présentation du site</h2>
              <p>
                En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004
                pour la confiance dans l'économie numérique, il est précisé aux
                utilisateurs du site www.in-print.net, l'identité des différents
                intervenants dans le cadre de sa réalisation et de son suivi.
              </p>
              <p>
                Le site <a href="http://www.in-print.net">www.in-print.net</a>{' '}
                est édité par la société IN-PRINT
                <br />
                La société IN-PRINT, est enregistrée au Tribunal de Commerce de
                Toulouse – France, sous le numéro 518 365 234.
              </p>
            </div>
            <dl>
              <div className="card">
                <dl>
                  <dt className="h2">1- Editeur &amp; propriétaire</dt>
                  <dd className="m0">
                    <address>
                      IN-PRINT
                      <br />
                      ZI Le Casque
                      <br />
                      31 rue Aristide Bergès
                      <br />
                      31270 CUGNAUX
                      <br />
                    </address>
                    <dl className="m0">
                      <dd>SARL au capital de 326 116€</dd>
                      <dd>RCS Toulouse 518 365 234</dd>
                      <dd>
                        <a href="mailto:contact@oyp.fr">contact@oyp.fr</a> - 05
                        61 24 65 35
                      </dd>
                      <dt>N° de TVA intracommunautaire :</dt>
                      <dd>FR 565 183 652 34</dd>
                      <dt>Créateur :</dt>
                      <dd>
                        IN-PRINT{' '}
                        <a href="http://www.in-print.net">www.in-print.net</a>
                      </dd>
                      <dt>Responsable de la publication :</dt>
                      <dd>Lionel PORTA</dd>
                      <dt>Hébergeur</dt>
                      <dd className="m0">
                        Le site www.in-print.net, est hébergé sur les matériels
                        informatiques de la société OVH dont le siège social est
                        situé : 140 Quai du Sartel – 59100 Roubaix
                      </dd>
                    </dl>
                  </dd>
                </dl>
              </div>

              <div className="card">
                <dl>
                  <dt className="h2">
                    2- Conditions générales d'utilisation du site et des
                    services proposés
                  </dt>
                  <dd>
                    <p>
                      L'utilisation du site www.in-print.net implique
                      l'acceptation pleine et entière des conditions générales
                      d'utilisation ci-après décrites. Ces conditions
                      d'utilisation sont susceptibles d'être modifiées ou
                      complétées à tout moment, les utilisateurs du site sont
                      donc invités à les consulter de manière régulière.
                    </p>
                    <p>
                      Ce site est normalement accessible à tout moment aux
                      utilisateurs. Une interruption pour raison de maintenance
                      technique peut être toutefois décidé par IN-PRINT, qui
                      s'efforcera alors de communiquer préalablement aux
                      utilisateurs les dates et heures de l'intervention.
                    </p>
                    <p>
                      Le site www.in-print.net est mis à jour régulièrement par
                      l'éditeur.
                    </p>
                    <p>
                      De la même façon, les mentions légales peuvent être
                      modifiées à tout moment : elles s'imposent néanmoins à
                      l'utilisateur qui est invité à s'y référer le plus souvent
                      possible afin d'en prendre connaissance.
                    </p>
                  </dd>
                </dl>
              </div>

              <div className="card">
                <dl>
                  <dt className="h2">3- Description des services fournis</dt>
                  <dd>
                    <p>
                      Le site www.in-print.net a pour objet de fournir une
                      information concernant l'ensemble des activités de la
                      société SARL IN-PRINT.
                    </p>
                    <p>
                      La société SARL IN-PRINT s'efforce de fournir sur le site
                      www.in-print.net des informations aussi précises que
                      possible.
                    </p>
                    <p>
                      Toutefois, elle ne pourra être tenue responsable des
                      omissions, des inexactitudes et des carences dans la mise
                      à jour, qu'elles soient de son fait ou du fait des tiers
                      partenaires qui lui fournissent ces informations.
                    </p>
                    <p>
                      Toutes les informations indiquées sur le site
                      www.in-print.net sont données à titre indicatif et sont
                      susceptibles d'évoluer.
                    </p>
                    <p>
                      Par ailleurs, les renseignements figurant sur le site
                      www.in-print.net ne sont pas exhaustifs. Ils sont donnés
                      sous réserve de modifications ayant été apportées depuis
                      leur mise en ligne.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">
                    4- Limitations contractuelles sur les données techniques
                  </dt>
                  <dd>
                    <p>Le site utilise la technologie JavaScript.</p>
                    <p>
                      Le site Internet ne pourra être tenu responsable de
                      dommages matériels liés à l'utilisation du site. De plus,
                      l'utilisateur du site s'engage à accéder au site en
                      utilisant un matériel récent, ne contenant pas de virus et
                      avec un navigateur de dernière génération mis à jour.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">
                    5- Propriété intellectuelle et contrefaçons
                  </dt>
                  <dd>
                    <p>
                      La SARL IN-PRINT est propriétaire des droits de propriété
                      intellectuelle ou détient les droits d'usage sur tous les
                      éléments accessibles sur le site, notamment les textes,
                      images, graphismes, logos, icônes, sons, logiciels.
                    </p>
                    <p>
                      Toute reproduction, représentation, modification,
                      publication, adaptation de tout ou partie des éléments du
                      site, quel que soit le moyen ou le procédé utilisé, est
                      interdite, sauf autorisation écrite préalable de la SARL
                      IN-PRINT.
                    </p>
                    <p>
                      Toute exploitation non autorisée du site, ou de l'un
                      quelconque des éléments qu'il contient, sera considérée
                      comme constitutive d'une contrefaçon et poursuivie
                      conformément aux dispositions des articles L.335-2 et
                      suivants du Code de Propriété Intellectuelle.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">6- Limitations de responsabilité</dt>
                  <dd>
                    <p>
                      La SARL IN-PRINT ne pourra être tenue responsable des
                      dommages directs et indirects causés au matériel de
                      l'utilisateur, lors de l'accès au site www.in-print.net et
                      résultant soit de l'utilisation d'un matériel ne répondant
                      pas aux spécifications indiquées au point 4, soit de
                      l'apparition d'un bug ou d'une incompatibilité.
                    </p>
                    <p>
                      La SARL IN-PRINT ne pourra également être tenue
                      responsable des dommages indirects (tels par exemple
                      qu'une perte de marché ou la perte d'une opportunité de
                      contrat) consécutifs à l'utilisation du site
                      www.in-print.net.
                    </p>
                    <p>
                      Des espaces interactifs (possibilité de poser des
                      questions dans l'espace contact) sont à la disposition des
                      utilisateurs.
                    </p>
                    <span id="donnees-personnelles" />
                    <p>
                      La SARL IN-PRINT se réserve le droit de supprimer, sans
                      mise en demeure préalable, tout contenu déposé dans cet
                      espace qui contreviendrait à la législation applicable en
                      France, en particulier aux dispositions relatives à la
                      protection des données. Le cas échéant, la SARL IN-PRINT
                      se réserve également la possibilité de mettre en cause la
                      responsabilité civile et/ou pénale de l'utilisateur,
                      notamment en cas de message à caractère raciste,
                      injurieux, diffamant ou pornographique, quel que soit le
                      support utilisé (texte, photographie…).
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">7- Gestion des données personnelles</dt>
                  <dd>
                    <p>
                      En France, les données personnelles sont notamment
                      protégées par la loi n° 78-87 du 6 janvier 1978, la loi n°
                      2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal
                      et la Directive Européenne du 24 octobre 1995.
                    </p>
                    <p>
                      A l'occasion de l'utilisation du site www.in-print.net
                      peuvent être recueillies : les URLs des liens par
                      l'intermédiaire desquels l'utilisateur a accédé au site
                      www.in-print.net, le fournisseur d'accès de l'utilisateur,
                      l'adresse de protocole Internet (IP) de l'utilisateur.
                    </p>
                    <p>
                      En tout état de cause, la SARL IN-PRINT ne collecte des
                      informations personnelles relatives à l'utilisateur que
                      pour le besoin de certains services proposés par le site
                      www.in-print.net. L'utilisateur fournit ces informations
                      en toute connaissance de cause, notamment lorsqu'il
                      procède par lui-même à leur saisie. Il est alors précisé à
                      l'utilisateur du site l'obligation ou non de fournir ces
                      informations.
                    </p>
                    <p>
                      Conformément aux dispositions des articles 38 et suivants
                      de la loi 78-17 du 6 janvier 1978 relative à
                      l'informatique, aux fichiers et aux libertés, tout
                      utilisateur dispose d'un droit d'accès, de rectification
                      et d'opposition aux données personnelles le concernant, en
                      effectuant sa demande écrite et signée, accompagnée d'une
                      copie du titre d'identité avec signature du titulaire de
                      la pièce, en précisant l'adresse à laquelle la réponse
                      doit être envoyée.
                    </p>
                    <p>
                      Pour toute question sur les données personnelles, vous
                      pouvez nous contacter au 05 61 24 65 35.
                    </p>
                    <p>
                      Aucune information personnelle de l'utilisateur du site
                      www.in-print.net n'est publiée à l'insu de l'utilisateur,
                      échangée, transférée, cédée ou vendue sur un support
                      quelconque à des tiers. Seule l'hypothèse du rachat de la
                      SARL IN-PRINT et de ses droits permettrait la transmission
                      des dites informations à l'éventuel acquéreur qui serait à
                      son tour tenu de la même obligation de conservation et de
                      modification des données vis-à-vis de l'utilisateur du
                      site.
                    </p>
                    <p>
                      Les bases de données sont protégées par les dispositions
                      de la loi du 1er juillet 1998 transposant la directive
                      96/9 du 11 mars 1996 relative à la protection juridique
                      des bases de données.
                    </p>
                    <p>
                      Utilisation des e-mails :<br />
                      Dès lors que vous êtes devenu client de notre société,
                      nous serons susceptibles de vous adresser nos offres
                      commerciales par tout moyen, dont la messagerie
                      électronique, dès lors qu'elles nous sembleront de nature
                      à vous intéresser.
                    </p>
                    <span id="cookies" />
                    <p>
                      Dans nos envois par e-mail, nous précisons clairement dans
                      l'objet du message, qui apparaît dans la boîte aux lettres
                      électronique, l'identité, la marque IN-PRINT ou la nature
                      commerciale de notre proposition.
                    </p>
                    <p>
                      En pied de message, vous trouvez également la possibilité,
                      par simple clic, d'exercer votre droit d'opposition à
                      l'envoi de tout nouveau message.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">8- Liens hypertextes et cookies</dt>
                  <dd>
                    <p>
                      Le site www.in-print.net contient un certain nombre de
                      liens hypertextes vers d'autres sites, mis en place avec
                      l'autorisation de la SARL IN-PRINT.
                    </p>
                    <p>
                      Cependant, la SARL IN-PRINT n'a pas la possibilité de
                      vérifier le contenu des sites ainsi visités et n'assumera,
                      en conséquence, aucune responsabilité de ce fait.
                    </p>
                    <p>
                      La SARL IN-PRINT autorise tout site Internet précisant les
                      mentions obligatoires, ou tout autre support, à citer
                      www.in-print.net, à mettre en place un lien hypertexte
                      pointant vers la page d'accueil du site à l'adresse
                      http://www.in-print.net ou toute autre page, uniquement
                      après l'accord préalable du Responsable de la Publication.
                    </p>
                    <p>
                      La navigation sur le site est susceptible de provoquer
                      l'installation de cookie(s) sur l'ordinateur de
                      l'utilisateur. Un cookie est un fichier de petite taille
                      qui ne permet pas l'identification de l'utilisateur mais
                      qui enregistre des informations relatives à la navigation
                      d'un ordinateur sur un site. Les données ainsi obtenues
                      visent à faciliter la navigation ultérieure sur le site et
                      ont également vocation à permettre diverses mesures de
                      fréquentation.
                    </p>
                    <p>
                      Le refus d'installation d'un cookie peut entraîner
                      l'impossibilité d'accéder à certains services.
                      L'utilisateur peut toutefois configurer son ordinateur de
                      la manière suivante, pour refuser l'installation des
                      cookies :
                    </p>
                    <ul>
                    <li>Sous Google Chrome : cliquez sur le cadenas à gauche de la barre d'adresse. Cliquez ensuite sur "Cookies". Vous pouvez ensuite bloquer ou supprimer les cookies de votre choix. </li>
                    <li>Sous Firefox : cliquez sur le bouclier à gauche de la barre d'adresse. Cliquez ensuite sur "Paramètres de protection". Dans la section "Protection renforcée contre le pistage", sélectionnez le profil "Personnalisé" puis sélectionnez ensuite le type de cookies que vous souhaitez bloquer. </li>
                    </ul>
                    <p>
                      Pour plus d'informations sur les cookies, visitez le{' '}
                      <a href="https://www.cnil.fr/">site de la CNIL</a>.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">
                    9- Droit applicable et attribution de juridiction
                  </dt>
                  <dd>
                    <p>
                      Tout litige en relation avec l'utilisation du site
                      www.in-print.net est soumis au droit français. Il est fait
                      attribution exclusive de juridiction aux tribunaux
                      compétents de Toulouse.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">10- Les principales lois concernées</dt>
                  <dd>
                    <p>
                      Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la
                      loi n° 2004-801 du 6 août 2004 relative à l'informatique,
                      aux fichiers et aux libertés.
                    </p>
                    <p>
                      Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                      l'économie numérique.
                    </p>
                  </dd>
                </dl>
              </div>
              <div className="card">
                <dl>
                  <dt className="h2">11- Lexique</dt>
                  <dd>
                    <dl>
                      <dt>Utilisateur :</dt>
                      <dd>
                        Internaute se connectant, utilisant le site susnommé.
                      </dd>
                      <dt>Informations personnelles :</dt>
                      <dd>
                        « Les informations qui permettent, sous quelque forme
                        que ce soit, directement ou non, l'identification des
                        personnes physiques auxquelles elles s'appliquent »
                        (article 4 de la loi n° 78-17 du 6 janvier 1978).
                      </dd>
                    </dl>
                  </dd>
                </dl>
              </div>
            </dl>
            <p className="text-center m0">Mise à jour : 08/03/2019</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default MentionsLegalesPage;
